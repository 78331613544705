<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <custom-loading
      :loading="loading_remitos || loading"
      color="greyTxt"
      opacity=".4"
    />
    <back class="mt-6" />
    <div
      class="ml-2 font-weight-medium text-h6 greyTxt--text pt-4 mb-4 "
    >
      Remitos
      <template v-if="id_pedido || id">
        asociados al pedido

        <span
          class="sendBtn--text font-weight-bold cursor-pointer"
          @click="openDetallesPedido({raz_soc:cliente_nombre,id,id_pedido})"
        >
          #{{ id_pedido || id }}
        </span>
        <span
          v-if="id"
          class="font-weight-regular text-h6 primary--text d-block d-lg-inline-block"
        >
          ( <span class="d-none d-lg-inline-block">Orden de entrega</span> {{ id }})
        </span>
        <span
          v-if="cliente_nombre"
          class="ml-3 font-weight-regular text-subtitle-2 lightGreyTxt--text"
        >
          {{ cliente_nombre }}
        </span>
      </template>
    </div>

    <v-row>
      <v-col
        cols="24"
        md="12"
      >
        <v-card elevation="2">
          <!-- Tabla de Remitos -->
          <generic-table
            :loading="loading_remitos"
            :headers="headers_remitos"
            :items="remitos"
            :actions="actions_remitos"
            :sort-by="['id_remito']"
            @openQR="openQR"
            @openFoto="openFoto"
            @openDetalles="openDetalles"
            @downloadRemito="downloadRemito"
            @delRemito="delRemito"
          />
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog del botón Ver QR -->
    <v-dialog
      v-model="dialog.qr"
      width="500"
    >
      <v-card>
        <v-card-title
          class="text-h6 grey lighten-2"
          primary-title
        >
          Código QR asociado
        </v-card-title>
        <div
          align="center"
          class="py-6"
        >
          <generic-qr :url="url" />
        </div>
        <!-- QR -->

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="dialog.qr = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-modal
      :open.sync="confirmDel"
      :is-overlay="loading"
      :text="`Confirma la eliminación del remito ${numero_remito_del}`"
      @confirm="handleDelConfirm"
    />
  </v-container>
</template>

<script>
  import { genericRequest } from '@/api/modules'
  import { remitoController } from '@/pdf/remitos'
  import { get, call } from 'vuex-pathify'
  export default {
    name: 'RemitosView',

    data () {
      return {
        confirmDel: false,
        loading: false,
        remitoDel: null,
        // si es true, abre el dialog correspondiente
        dialog: {
          qr: false,
          image: false,
          detail: false,
        },
        url: '', // contiene a la url que generará el código QR
      }
    },

    computed: {
      group: get('user/group'),
      remitos: get('remitos/items'),
      headers_remitos: get('remitos/headers'),
      loading_remitos: get('remitos/loading'),
      count_remitos: get('remitos/count'),
      id () {
        return this.$route?.params?.id
      },
      id_pedido () {
        return this.$route?.params?.id_pedido
      },
      cliente_nombre () {
        return this.$route?.params?.cliente_nombre
      },
      numero_remito_del () {
        return this.remitoDel?.numero_remito
      },
      id_proveed () {
        return this.$route?.query?.id_proveed
      },
      actions_remitos () {
        return [
          {
            icon: '',
            title: 'Descargar Remito',
            color: 'sendBtn',
            emit: 'downloadRemito',
            attr: { dark: true },
          },
          {
            icon: 'qrcode-scan',
            title: 'Ver QR',
            color: 'sendBtn',
            emit: 'openQR',
            attr: { dark: true },
          },
          // {
          //   icon: 'mdi-image-size-select-actual',
          //   title: 'Ver foto',
          //   color: 'primary',
          //   emit: 'openFoto',
          // },
          {
            icon: 'alert-circle',
            color: 'reemplazoTxt',
            attr: { icon: true, elevation: '0', depressed: true, dark: true },
            condition: [{ field: 'archivos_cliente_pendiente', value: true }],
            tooltip: 'Falta adjuntar foto del remito',
            hidden: this.group === 'proveedor' || this.group === 'admin',
          },
          {
            icon: 'eye',
            title: 'Ver detalle',
            color: 'primary',
            emit: 'openDetalles',
          },

          // {
          //   icon: 'delete',
          //   title: 'Eliminar',
          //   color: 'primary',
          //   emit: 'delRemito',
          //   attr: { dark: true },
          //   hidden: this.group === 'clienteInterno',
          // },
        ]
      },
    },
    created () {
      this.initialize()
    },
    methods: {
      getDataRemitos: call('remitos/getData'),
      initialize () {
        this.getRemitos()
      },
      async getRemitos () {
        await this.getDataRemitos({ idemcmc: this.id, id_proveed: this.id_proveed })
      },

      openQR (remito) {
        this.url = remito.id_control
        this.dialog.qr = true
      },
      openFoto (details) {
        console.log('Foto', details)
      },
      openDetalles (details) {
        if (this.group === 'clienteInterno') {
          this.$router.push(`/remito/${details.id_control}/${details.idemcmc}/${details.id_pedido}?id_proveed=${this.id_proveed}`)
        } else {
          this.$router.push(`/remito/${details.id_control}/${details.idemcmc}/${details.id_pedido}`)
        }
      },
      openDetallesPedido (details) {
        if (this.group === 'clienteInterno') {
          this.$router.push(`/pedido/${details.id}/${details.raz_soc}/${details.id_pedido}?id_proveed=${this.id_proveed}`)
        } else {
          this.$router.push(`/pedido/${details.id}/${details.raz_soc}/${details.id_pedido}`)
        }
      },
      downloadRemito (remito) {
        remitoController.dowloadRemito(remito)
      },
      delRemito (remito) {
        this.confirmDel = true
        this.remitoDel = remito
      },
      async handleDelConfirm () {
        this.loading = true
        try {
          await genericRequest({
            url: `/remitos/${this.remitoDel.id_control}?change_est_comp=1`,
            method: 'PUT',
          })
          this.initialize()
        } catch (error) {

        } finally {
          this.loading = false
          this.confirmDel = false
          this.remitoDel = null
        }
      },
    },

  }
</script>
